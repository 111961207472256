export const xml2json = (xml, tab) => {
  var X = {
    toObj: function (xml) {
      var o = {};
      if (xml.nodeType == 1) {
        // element node ..
        if (xml.attributes.length)
          // element with attributes  ..
          for (var i = 0; i < xml.attributes.length; i++)
            o["@" + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
        if (xml.firstChild) {
          // element has child nodes ..
          var textChild = 0,
            cdataChild = 0,
            hasElementChild = false;
          for (var n = xml.firstChild; n; n = n.nextSibling) {
            if (n.nodeType == 1) hasElementChild = true;
            else if (n.nodeType == 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/))
              textChild++; // non-whitespace text
            else if (n.nodeType == 4) cdataChild++; // cdata section node
          }
          if (hasElementChild) {
            if (textChild < 2 && cdataChild < 2) {
              // structured element with evtl. a single text or/and cdata node ..
              X.removeWhite(xml);
              for (
                var firstChildValue = xml.firstChild;
                firstChildValue;
                firstChildValue = firstChildValue.nextSibling
              ) {
                if (firstChildValue.nodeType == 3)
                  // text node
                  o["#text"] = X.escape(firstChildValue.nodeValue);
                else if (firstChildValue.nodeType == 4)
                  // cdata node
                  o["#cdata"] = X.escape(firstChildValue.nodeValue);
                else if (o[firstChildValue.nodeName]) {
                  // multiple occurence of element ..
                  if (o[firstChildValue.nodeName] instanceof Array)
                    o[firstChildValue.nodeName][o[firstChildValue.nodeName].length] =
                      X.toObj(firstChildValue);
                  else
                    o[firstChildValue.nodeName] = [
                      o[firstChildValue.nodeName],
                      X.toObj(firstChildValue)
                    ];
                } // first occurence of element..
                else o[firstChildValue.nodeName] = X.toObj(firstChildValue);
              }
            } else {
              // mixed content
              if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
              else o["#text"] = X.escape(X.innerXml(xml));
            }
          } else if (textChild) {
            // pure text
            if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
            else o["#text"] = X.escape(X.innerXml(xml));
          } else if (cdataChild) {
            // cdata
            if (cdataChild > 1) o = X.escape(X.innerXml(xml));
            else
              for (var firstChild = xml.firstChild; firstChild; firstChild = firstChild.nextSibling)
                o["#cdata"] = X.escape(firstChild.nodeValue);
          }
        }
        if (!xml.attributes.length && !xml.firstChild) o = null;
      } else if (xml.nodeType == 9) {
        // document.node
        o = X.toObj(xml.documentElement);
      } else alert("unhandled node type: " + xml.nodeType);
      return o;
    },
    toJson: function (o, name, ind) {
      var json = name ? '"' + name + '"' : "";
      if (o instanceof Array) {
        for (var i = 0, n = o.length; i < n; i++) o[i] = X.toJson(o[i], "", ind + "\t");
        json +=
          (name ? ":[" : "[") +
          (o.length > 1
            ? "\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind
            : o.join("")) +
          "]";
      } else if (o == null) json += (name && ":") + "null";
      else if (typeof o == "object") {
        var arr = [];
        for (var m in o) arr[arr.length] = X.toJson(o[m], m, ind + "\t");
        json +=
          (name ? ":{" : "{") +
          (arr.length > 1
            ? "\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind
            : arr.join("")) +
          "}";
      } else if (typeof o == "string") json += (name && ":") + '"' + o.toString() + '"';
      else json += (name && ":") + o.toString();
      return json;
    },
    innerXml: function (node) {
      var s = "";
      if ("innerHTML" in node) s = node.innerHTML;
      else {
        var asXml = function (n) {
          var s = "";
          if (n.nodeType == 1) {
            s += "<" + n.nodeName;
            for (var i = 0; i < n.attributes.length; i++)
              s +=
                " " +
                n.attributes[i].nodeName +
                '="' +
                (n.attributes[i].nodeValue || "").toString() +
                '"';
            if (n.firstChild) {
              s += ">";
              for (var c = n.firstChild; c; c = c.nextSibling) s += asXml(c);
              s += "</" + n.nodeName + ">";
            } else s += "/>";
          } else if (n.nodeType == 3) s += n.nodeValue;
          else if (n.nodeType == 4) s += "<![CDATA[" + n.nodeValue + "]]>";
          return s;
        };
        for (var c = node.firstChild; c; c = c.nextSibling) s += asXml(c);
      }
      return s;
    },
    escape: function (txt) {
      return (
        txt
          .replace(/[\\]/g, "\\\\")
          // eslint-disable-next-line no-useless-escape
          .replace(/[\"]/g, '\\"')
          .replace(/[\n]/g, "\\n")
          .replace(/[\r]/g, "\\r")
      );
    },
    removeWhite: function (e) {
      e.normalize();
      for (var n = e.firstChild; n; ) {
        if (n.nodeType == 3) {
          // text node
          if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
            // pure whitespace text node
            var nxt = n.nextSibling;
            e.removeChild(n);
            n = nxt;
          } else n = n.nextSibling;
        } else if (n.nodeType == 1) {
          // element node
          X.removeWhite(n);
          n = n.nextSibling;
        } // any other node
        else n = n.nextSibling;
      }
      return e;
    }
  };
  if (xml.nodeType == 9)
    // document node
    xml = xml.documentElement;
  var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
  return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
};

const addCommaToValue = (value) => {
  if (value) return `${value},`;
  return ``;
};

const failsafe = (functionToFailSafe) => {
  try {
    return functionToFailSafe();
  } catch (err) {
    console.log(err);
  }
};

const formatLocation = (locationData) => {
  try {
    return (
      addCommaToValue(locationData["cdsd:CountrySubdivisionCode"]) +
      addCommaToValue(locationData["cdsd:City"]) +
      addCommaToValue(locationData["cdsd:Line1"]) +
      addCommaToValue(locationData["cdsd:PostalZipCode"]["cdsd:PostalCode"])
    );
  } catch (err) {
    console.log(err);
  }
};

export const turnXMLFormatToOurDataFormat = (unFormattedJSON) => {
  let clientsRaw = [];
  const PatientRecordValue = unFormattedJSON["cds:OmdCds"]["cds:PatientRecord"];
  if (!Array.isArray(PatientRecordValue)) {
    clientsRaw = [PatientRecordValue];
  } else {
    clientsRaw = PatientRecordValue;
  }

  const clients = clientsRaw.map((rawClient) => {
    const data = rawClient["cds:Demographics"];
    const nameData = data["cds:Names"]["cdsd:LegalName"];
    const first_name = failsafe(() => nameData["cdsd:FirstName"]["cdsd:Part"]);
    const last_name = failsafe(() => nameData["cdsd:LastName"]["cdsd:Part"]);
    const dob = failsafe(() => data["cds:DateOfBirth"]);
    const locationData = failsafe(() => data["cds:Address"]["cdsd:Structured"]);
    const location = formatLocation(locationData);
    const healthcard_expiry = failsafe(() => data["cds:HealthCard"]["cdsd:Expirydate"]);
    const healthcard_number = failsafe(() => data["cds:HealthCard"]["cdsd:Number"]);
    const version_code = failsafe(() => data["cds:HealthCard"]["cdsd:Version"]);
    return {
      ...(first_name && { first_name: first_name }),
      ...(last_name && { last_name: last_name }),
      ...(dob && { dob: dob }),
      ...(location && { location: location }),
      ...(healthcard_expiry && { healthcard_expiry: healthcard_expiry }),
      ...(healthcard_number && { healthcard_number: healthcard_number }),
      ...(version_code && { version_code: version_code })
    };
  });

  return clients;
};

// t.string("first_name")
// t.string("last_name")
// t.string("pronoun") // Not available it looks like?
// t.string("nickname") // Not available it looks like?
// t.date("dob")
// t.boolean("healthcard_validity") // We could check if there health card is expired, but not valid.
// t.text("general_info") // ?
// t.boolean("inactive", default: false) // ?
// t.string("location")
// t.date("healthcard_expiry")
// t.string("healthcard_message")
// t.text("care_plan")
// t.string("healthcard_number")
// t.string("version_code")
