import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { formatDateTimeByString } from "../../timeUtils";
import axios from "axios";
import useAsyncCallback from "../../hooks/useAsyncCallback";
import TableViewContextProvider from "./TableViewContext";
import TableViewPopup from "./table_view_popup";
import { RefreshShiftLogsContext } from "./RefreshShiftLogsContext";
import ReadMore from "../read_more";
import { goToEditShiftLogPage, goToNewShitLogPage } from "./utility_functions";

const TableView = ({ date, shiftNum, location, newShiftLogPath, editShiftLogPath }) => {
  const isShowingAllShift = shiftNum === "All Shifts";
  const {
    loading,
    error,
    data: formattedShiftLogs,
    execute: refresh
  } = useAsyncCallback(async () => getShiftLogsGroupByClient(date, shiftNum, location), []);

  const [hideImageUrls, setHideImageUrls] = useState([]);

  const onImageLoadError = (url) => {
    setHideImageUrls([...hideImageUrls, url]);
  };

  return (
    <TableViewContextProvider pageParams={{ date, shiftNum, location }}>
      <RefreshShiftLogsContext.Provider value={refresh}>
        <div className="table-container">
          {loading && <div>loading...</div>}
          {error && <div>Error: {error.message}</div>}
          {!loading && (
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Reg#</th>
                  <th>Client Name</th>
                  <th>Client Detail</th>
                  <th>Care Plan</th>
                  <th>Shift Number</th>
                  <th>Shift Note (What happened?)123ABC</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {formattedShiftLogs.map((shiftLog, index) => {
                  return (
                    <Fragment key={index}>
                      <tr id={`${replaceWhitespaceWithUnderscore(shiftLog.client_name)}`}>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>{shiftLog.client_id}</td>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>{shiftLog.client_name}</td>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>
                          <div>{shiftLog.client_detail.pronoun}</div>
                          <div>{shiftLog.client_detail.healthcard_number}</div>
                          <div>{shiftLog.client_detail.dob}</div>
                          {shiftLog.client_detail.avatar_url &&
                            !hideImageUrls.includes(shiftLog.client_detail.avatar_url) && (
                              <img
                                src={shiftLog.client_detail.avatar_url}
                                className="table-container-avatar_url"
                                onError={() => onImageLoadError(shiftLog.client_detail.avatar_url)}
                              />
                            )}
                        </td>
                        <td rowSpan={isShowingAllShift ? 3 : 1}>
                          <div className="table-container-log-description">
                            <ReadMore text={shiftLog.client_detail.care_plan}></ReadMore>
                          </div>
                        </td>
                        {(isShowingAllShift || shiftNum === "1st") && (
                          <>
                            <TableTd
                              date={date}
                              newShiftLogPath={newShiftLogPath}
                              editShiftLogPath={editShiftLogPath}
                              rowShiftNum="1st"
                              shiftLog={shiftLog}
                            />
                          </>
                        )}
                        {shiftNum === "2nd" && (
                          <>
                            <TableTd
                              date={date}
                              newShiftLogPath={newShiftLogPath}
                              editShiftLogPath={editShiftLogPath}
                              rowShiftNum="2nd"
                              shiftLog={shiftLog}
                            />
                          </>
                        )}
                        {shiftNum === "3rd" && (
                          <>
                            <TableTd
                              date={date}
                              newShiftLogPath={newShiftLogPath}
                              editShiftLogPath={editShiftLogPath}
                              rowShiftNum="3rd"
                              shiftLog={shiftLog}
                            />
                          </>
                        )}
                      </tr>
                      {isShowingAllShift && (
                        <tr>
                          <TableTd
                            date={date}
                            newShiftLogPath={newShiftLogPath}
                            editShiftLogPath={editShiftLogPath}
                            rowShiftNum="2nd"
                            shiftLog={shiftLog}
                          />
                        </tr>
                      )}
                      {isShowingAllShift && (
                        <tr>
                          <TableTd
                            date={date}
                            newShiftLogPath={newShiftLogPath}
                            editShiftLogPath={editShiftLogPath}
                            rowShiftNum="3rd"
                            shiftLog={shiftLog}
                          />
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          <TableViewPopup />
        </div>
      </RefreshShiftLogsContext.Provider>
    </TableViewContextProvider>
  );
};
function TableTd({ rowShiftNum, shiftLog, date, newShiftLogPath, editShiftLogPath }) {
  const shiftLogToRender = shiftLog.shifts.find((shift) => shift.shift_num === rowShiftNum);
  const isNewLog = !(shiftLogToRender && shiftLogToRender.id);
  const action = isNewLog ? "new" : "edit";

  const onAction = () => {
    if (!shiftLogToRender) {
      goToNewShitLogPage(newShiftLogPath, rowShiftNum, date, shiftLog.client_id);
    } else {
      goToEditShiftLogPage(editShiftLogPath, shiftLogToRender.id, date, rowShiftNum);
    }
  };

  if (!shiftLogToRender)
    return (
      <>
        <td>{rowShiftNum}</td>
        <td>
          <div className="table-container-log-description">
            <div>
              <button
                id={`${replaceWhitespaceWithUnderscore(
                  shiftLog.client_name
                )}_shift_${rowShiftNum}_${action}_btn`}
                className={`button is-small ${action == "new" ? "is-primary" : ""} `}
                onClick={onAction}
              >
                {action.charAt(0).toUpperCase() + action.slice(1)}
              </button>
            </div>
          </div>
        </td>
        <td></td>
      </>
    );

  return (
    <>
      <td>{rowShiftNum}</td>
      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLogToRender.log_description ?? ""}></ReadMore>
          <div>
            <button
              id={`${replaceWhitespaceWithUnderscore(
                shiftLog.client_name
              )}_shift_${rowShiftNum}_${action}_btn`}
              className={`button is-small ${action == "new" ? "is-primary" : ""} `}
              onClick={onAction}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </button>
          </div>
        </div>
      </td>
      <td>
        {shiftLog.shifts[rowShiftNum] ? formatDateTimeByString(shiftLogToRender.updated_at) : ""}
      </td>
    </>
  );
}

TableView.propTypes = {
  date: PropTypes.string.isRequired,
  shiftNum: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  newShiftLogPath: PropTypes.string,
  editShiftLogPath: PropTypes.string
};
TableTd.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  shiftLog: PropTypes.object.isRequired,
  date: PropTypes.object,
  newShiftLogPath: PropTypes.string,
  editShiftLogPath: PropTypes.string
};

export default TableView;
async function getShiftLogsGroupByClient(date, shiftNum, location) {
  const res = await axios.post("/shift_logs/group_by_client.json", {
    date: [date],
    shift_num: shiftNum,
    location
  });
  return res.data;
}

function replaceWhitespaceWithUnderscore(str) {
  return str.replace(/\s+/g, "_");
}
