/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import axios from "axios";
import ReactSelect, { components } from "react-select";
import JSZip from "jszip";

import { turnXMLFormatToOurDataFormat, xml2json } from "./utility_functions";

const turnXMLStringIntoObjectArray = (xmlString) => {
  // turn string to xml
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlString, "text/xml");
  // turn xml into object array
  const jsonString = xml2json(xml);
  const json = JSON.parse(jsonString.replace("undefined", ""));
  return turnXMLFormatToOurDataFormat(json);
};

const generateTestId = (id) => {
  return `clients-page-import-export-modal-${id}`;
};

const Option = (props) => {
  return (
    <div explorator_test_id={generateTestId(`location-select-option-${props.label}`)}>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ImportExportClientsModal = ({ locations, showButton = true }) => {
  const [showModal, setShowModal] = useState(false);
  const [duplicateClients, setDuplicateClients] = useState([]);
  const [newClients, setNewClients] = useState([]);

  const [message, setMessage] = useState();
  const [success, setSuccess] = useState();
  const [location, setLocation] = useState();

  const showError = (message) => {
    setSuccess(false);
    setMessage(message);
    setDuplicateClients([]);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileNameSplit = file.name.split(".");
    const fileTypeExtension = fileNameSplit[fileNameSplit.length - 1];

    setMessage(undefined); // reset error

    if (!(file && (fileTypeExtension === "xml" || fileTypeExtension === "zip"))) {
      showError("Failed to load data from file. Please confirm that file is the correct format.");
      return;
    }
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        if (fileTypeExtension !== "xml") {
          let clients = [];
          const zip = await JSZip.loadAsync(file);
          const arrayOfFileKeys = Object.keys(zip.files);
          for (let fileNameIndex = 0; fileNameIndex < arrayOfFileKeys.length; fileNameIndex++) {
            const fileKey = arrayOfFileKeys[fileNameIndex];
            const fileName = zip.files[fileKey].name;
            // If statement deals with files that are OS/software specific
            if (
              !fileName.startsWith("__MACOSX/") &&
              !fileName.startsWith(".DS_Store") &&
              !fileName.startsWith("Thumbs.db") &&
              !fileName.startsWith("desktop.ini") &&
              !fileName.endsWith(".log") &&
              !fileName.endsWith(".txt") &&
              !fileName.startsWith(".")
            ) {
              try {
                const fileData = await zip.files[fileKey].async("string");
                let formattedData = turnXMLStringIntoObjectArray(fileData);
                clients = [...clients, ...formattedData];
              } catch (err) {
                showError(`Failed to load data from the file ${fileName}`);
              }
            }
          }
          setNewClients(clients);
        } else {
          try {
            const xmlString = reader.result;
            let formattedData = turnXMLStringIntoObjectArray(xmlString);
            formattedData = formattedData.map((ele) => ({ ...ele }));

            setNewClients(formattedData);
          } catch (err) {
            showError(
              `Failed to load data from file. Please confirm that file is the correct format (.xml or zip).`
            );
          }
        }
      };
      await reader.readAsText(file);
    } catch (err) {
      showError(
        "Failed to load data from file. Please confirm that file is the correct format (.xml or zip)."
      );
    }
  };

  const reset = () => {
    setShowModal(false);
    setMessage();
    setNewClients([]);
  };

  const uploadClients = async () => {
    try {
      if (!location) {
        showError("Error: Please select location");
        return;
      }
      const res = await axios.post("/clients/upload_clients.json", {
        clients: newClients,
        location: location.value
      });
      if (res.status >= 299) throw res.data.message;
      setShowModal(false);
      setMessage();
      setNewClients([]);
      setSuccess(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
      if (err && err.response.data.message) {
        showError(err.response.data.message);
        if (err.response.data.message.includes("Duplicate")) {
          setDuplicateClients(() => {
            return err.response.data.duplicate_clients;
          });
        }
      } else
        showError("Failed to upload clients. Please try again then check or ask a admin for help.");
    }
  };

  const options = useMemo(() => {
    if (!locations) return [];
    return locations.map((location) => ({ value: location, label: location }));
  }, [locations]);

  const handleLocationChange = (selected) => {
    setLocation(selected);
  };

  return (
    <>
      <a
        className="button is-primary clients-page-import-export-modal-open-button"
        onClick={() => setShowModal(true)}
        explorator_test_id={generateTestId("open-modal-button")}
        id="client_page_header_import_clients"
        style={{
          display: showButton ? "block" : "none"
        }}
      >
        Import Clients
      </a>
      {showModal && (
        <div className="clients-page-import-export-modal-container">
          <div className="clients-page-import-export-modal">
            <i className="fa fa-times modal-close-icon" onClick={reset} />
            <h1 explorator_test_id={generateTestId("title")} className="title modal-title">
              Import Clients
            </h1>
            <div className="upload-button-file-input-container">
              <label className="file-label modal-file-input">
                <input
                  onChange={handleFileUpload}
                  className="file-input"
                  type="file"
                  name="import_file[attachment]"
                  explorator_test_id={generateTestId("upload-file-input")}
                />
                <span className="file-cta input-container">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
              </label>
              <button
                onClick={uploadClients}
                className="button is-primary clients-page-import-export-modal-upload-button"
                disabled={!location || !(newClients && newClients.length > 0)}
                explorator_test_id={generateTestId("upload-file-button")}
              >
                Upload
              </button>
              <div style={{ position: "relative" }} className="field">
                <label
                  style={{ position: "absolute", top: "-1.75rem", left: "1rem" }}
                  className="label"
                >
                  Location
                </label>
                <ReactSelect
                  options={options}
                  id="user_locations"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{
                    Option
                  }}
                  required
                  name={name}
                  onChange={handleLocationChange}
                  value={location}
                  styles={{
                    color: "black",
                    option: (baseStyles) => ({
                      ...baseStyles,
                      color: "black"
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: "30rem",
                      borderRadius: "290486px",
                      border: "1px solid #26547c",
                      minHeight: "3.5rem",
                      fontSize: "1.2rem",
                      padding: "0 1.5rem",
                      paddingLeft: "1rem",
                      boxShadow: "inset 0 1px 2px rgb(0 0 0 / 10%)"
                    })
                  }}
                />
              </div>
            </div>
            {message && (
              <>
                <br />
                <p
                  style={{ color: success ? "green" : "red" }}
                  className="clients-page-import-export-modal-message"
                >
                  {success ? "Success" : "Error"}: {message}
                </p>
                {duplicateClients && (
                  <>
                    {duplicateClients.map((client, index) => (
                      <p
                        key={`${client.first_name}-${client.last_name}-${index}`}
                        style={{ color: "red" }}
                        className="clients-page-import-export-modal-message"
                      >
                        {`${client.first_name} ${client.last_name}`}
                      </p>
                    ))}
                  </>
                )}
              </>
            )}
            <br />
            {newClients && newClients.length > 0 && (
              <div className="import-modal-table-container client-body-in-modal">
                <table>
                  <thead>
                    <tr className="clients-title">
                      <th>REG#</th>
                      <th>Client Name</th>
                      <th>Location</th>
                      <th>Date of Birth</th>
                      <th>Healthcard #</th>
                      <th>Healthcard Expiry</th>
                      <th>Healthcard Version</th>
                    </tr>
                  </thead>
                  <tbody className="client-box">
                    {newClients.map((newClient, index) => (
                      <React.Fragment
                        key={`${newClient.first_name}-${newClient.last_name}-${index}`}
                      >
                        <tr className="card-container__top clients-page-import-export-modal-client-container">
                          <td>{newClient.id}</td>
                          <td>{`${newClient.first_name} ${newClient.last_name}`}</td>
                          <td>{newClient.location}</td>
                          <td>{newClient.dob}</td>
                          <td>{newClient.healthcard_number}</td>
                          <td>{newClient.healthcard_expiry}</td>
                          <td>{newClient.version_code}</td>
                        </tr>
                        <tr className="space"></tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ImportExportClientsModal;
