import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReadMore from "../read_more";
import { formatDateTimeByString } from "../../timeUtils";
import axios from "axios";
import { goToEditShiftLogPage, goToNewShitLogPage } from "./utility_functions";

function replaceWhitespaceWithUnderscore(str) {
  return str.replace(/\s+/g, "_");
}

function numberOfDaysBetweenDates(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const isShowingAllShift = true;

const ShiftLogSummaryTableView = ({
  shiftNum,
  location,
  startDate,
  endDate,
  newShiftLogPath,
  editShiftLogPath
}) => {
  const [hideImageUrls, setHideImageUrls] = useState([]);
  const [clientsWithShiftLogs, setClientsWithShiftLogs] = useState([]);

  const datesToRender = useMemo(() => {
    const startDateJSDate = new Date(`${startDate} 00:00:00`);
    const endDateJSDate = new Date(`${endDate} 00:00:00`);
    if (!startDateJSDate || !endDateJSDate) return [];
    const numberOfDaysInSpan = numberOfDaysBetweenDates(startDateJSDate, endDateJSDate);
    let datesToRenderTemp = [];
    for (let dayCounter = 0; dayCounter <= numberOfDaysInSpan; dayCounter++) {
      const tempDate = new Date(startDateJSDate);
      tempDate.setDate(tempDate.getDate() + dayCounter);
      datesToRenderTemp.push({
        displayValue: formatDate(tempDate)
      });
    }
    return datesToRenderTemp;
  }, [startDate, endDate]);

  useEffect(() => {
    const loadShiftLogs = async () => {
      const res = await axios.post("/shift_logs/group_by_client.json", {
        start_date: startDate,
        end_date: endDate,
        shift_num: shiftNum,
        location
      });
      setClientsWithShiftLogs(res.data);
    };
    loadShiftLogs();
  }, [datesToRender]);

  const onImageLoadError = (url) => {
    setHideImageUrls([...hideImageUrls, url]);
  };

  return (
    <div className="table-container">
      <table className="table is-bordered is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th>Reg#</th>
            <th>Client Name</th>
            <th>Client Detail</th>
            <th>Care Plan</th>
            {datesToRender.map((dateToRender) => (
              <>
                <th>Date: {dateToRender.displayValue}</th>
                <th>Shift Number</th>
                <th>Shift Note (What happened?)</th>
                <th>Updated at</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {clientsWithShiftLogs.map((client_data, index) => {
            return (
              <Fragment key={index}>
                <tr id={`${replaceWhitespaceWithUnderscore(client_data.client_name)}`}>
                  <td rowSpan={isShowingAllShift ? 3 : 1}>{client_data.client_id}</td>
                  <td rowSpan={isShowingAllShift ? 3 : 1}>{client_data.client_name}</td>
                  <td rowSpan={isShowingAllShift ? 3 : 1}>
                    <div>{client_data.client_detail.pronoun}</div>
                    <div>{client_data.client_detail.healthcard_number}</div>
                    <div>{client_data.client_detail.dob}</div>
                    {client_data.avatar_url &&
                      !hideImageUrls.includes(client_data.client_detail.avatar_url) && (
                        <img
                          src={client_data.client_detail.avatar_url}
                          className="table-container-avatar_url"
                          onError={() => onImageLoadError(client_data.client_detail.avatar_url)}
                        />
                      )}
                  </td>
                  <td rowSpan={isShowingAllShift ? 3 : 1}>
                    <div className="table-container-log-description">
                      <ReadMore text={client_data.client_detail.care_plan}></ReadMore>
                    </div>
                  </td>
                  {datesToRender.map((dateToRender) => (
                    <TableTd
                      key={dateToRender + "1st"}
                      rowShiftNum="1st"
                      shiftLog={client_data}
                      date={dateToRender.displayValue}
                      newShiftLogPath={newShiftLogPath}
                      editShiftLogPath={editShiftLogPath}
                    />
                  ))}
                </tr>
                <tr>
                  {datesToRender.map((dateToRender) => (
                    <TableTd
                      key={dateToRender.displayValue + "2nd"}
                      date={dateToRender.displayValue}
                      rowShiftNum="2nd"
                      shiftLog={client_data}
                      newShiftLogPath={newShiftLogPath}
                      editShiftLogPath={editShiftLogPath}
                    />
                  ))}
                </tr>
                <tr>
                  {datesToRender.map((dateToRender) => (
                    <TableTd
                      key={dateToRender.displayValue + "3rd"}
                      date={dateToRender.displayValue}
                      rowShiftNum="3rd"
                      shiftLog={client_data}
                      newShiftLogPath={newShiftLogPath}
                      editShiftLogPath={editShiftLogPath}
                    />
                  ))}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function TableTd({ date, rowShiftNum, shiftLog, newShiftLogPath, editShiftLogPath }) {
  const shiftLogToRender = useMemo(() =>
    shiftLog.shifts.find(
      (shift) => {
        if (shift.shift_num === rowShiftNum && shift.log_date === date) return true;
        return false;
      },
      [shiftLog]
    )
  );

  const isNewLog = !(shiftLogToRender && shiftLogToRender.id);
  const action = isNewLog ? "new" : "edit";

  const onAction = () => {
    if (!shiftLogToRender) {
      goToNewShitLogPage(newShiftLogPath, rowShiftNum, date, shiftLog.client_id);
    } else {
      goToEditShiftLogPage(editShiftLogPath, shiftLogToRender.id, date, rowShiftNum);
    }
  };

  if (!shiftLogToRender)
    return (
      <>
        <td style={{ fontWeight: "bold", backgroundColor: "#26547c", color: "white" }}>{date}</td>
        <td>{rowShiftNum}</td>
        <td>
          <div>
            <button
              id={`${replaceWhitespaceWithUnderscore(
                shiftLog.client_name
              )}_shift_${rowShiftNum}_${action}_btn`}
              className={`button is-small ${action == "new" ? "is-primary" : ""} `}
              onClick={onAction}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </button>
          </div>
        </td>
        <td></td>
      </>
    );

  return (
    <>
      <td style={{ fontWeight: "bold", backgroundColor: "#26547c", color: "white" }}>{date}</td>
      <td>{rowShiftNum}</td>
      <td>
        <div className="table-container-log-description">
          <ReadMore text={shiftLogToRender.log_description ?? ""}></ReadMore>
          <div>
            <button
              id={`${replaceWhitespaceWithUnderscore(
                shiftLog.client_name
              )}_shift_${rowShiftNum}_${action}_btn`}
              className={`button is-small ${action == "new" ? "is-primary" : ""} `}
              onClick={onAction}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </button>
          </div>
        </div>
      </td>
      <td>
        {shiftLog.shifts[rowShiftNum] ? formatDateTimeByString(shiftLogToRender.updated_at) : ""}
      </td>
    </>
  );
}

ShiftLogSummaryTableView.propTypes = {
  date: PropTypes.string.isRequired,
  shiftLogs: PropTypes.array,
  shiftNum: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  newShiftLogPath: PropTypes.string,
  editShiftLogPath: PropTypes.string
};
TableTd.propTypes = {
  rowShiftNum: PropTypes.string.isRequired,
  shiftLog: PropTypes.object.isRequired,
  date: PropTypes.string,
  newShiftLogPath: PropTypes.string,
  editShiftLogPath: PropTypes.string
};

export default ShiftLogSummaryTableView;
