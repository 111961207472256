import React from "react";
import PropTypes from "prop-types";

import { useDenseViewContext } from "./DenseViewContext";
import ReadMore from "../read_more";
import ShiftLogContainerHospice from "./shift_log_container_hospice";
import {
  getClientPath,
  getEditShiftLogPath,
  getFormattedDateTime,
  getMaintenanceClass
} from "./utility_functions";

const ShiftLogContainer = ({ shiftLog }) => {
  const { state: filterFields } = useDenseViewContext();
  const OtherLogRows = () => {
    return (
      <div className="log-rows">
        {filterFields.meal_prep && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.meal_prep)}`}></span>
            <b>Meal Prep:</b>
            <div className="log-rows__text">{shiftLog.meal_prep_text}</div>
          </>
        )}

        {filterFields.laundry && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.laundry)}`}></span>
            <b>Laundry:</b>
            <div className="log-rows__text">{shiftLog.laundry_text}</div>
          </>
        )}

        {filterFields.goods && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.goods)}`}></span>
            <b>Goods Distributions:</b>
            <div className="log-rows__text">{shiftLog.goods_text}</div>
          </>
        )}
        {filterFields.bed_checks && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_checks)}`}></span>
            <b>Bed Checks:</b>
            <div className="log-rows__text">{shiftLog.bed_checks_text}</div>
          </>
        )}

        {filterFields.personal_care && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.personal_care)}`}
            ></span>
            <b>Personal Care:</b>
            <div className="log-rows__text">{shiftLog.personal_care_text}</div>
          </>
        )}

        {filterFields.room_cleaning && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.room_cleaning)}`}
            ></span>
            <b>Room Cleaning:</b>
            <div className="log-rows__text">{shiftLog.room_cleaning_text}</div>
          </>
        )}

        {filterFields.shower && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shower)}`}></span>
            <b>Shower:</b>
            <div className="log-rows__text">{shiftLog.shower_text}</div>
          </>
        )}

        {filterFields.shave && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shave)}`}></span>
            <b>Shave:</b>
            <div className="log-rows__text">{shiftLog.shave_text}</div>
          </>
        )}

        {filterFields.hair_wash && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_wash)}`}></span>
            <b>Hair Wash:</b>
            <div className="log-rows__text">{shiftLog.hair_wash_text}</div>
          </>
        )}

        {filterFields.hair_cut && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_cut)}`}></span>
            <b>Hair Cut:</b>
            <div className="log-rows__text">{shiftLog.hair_cut_text}</div>
          </>
        )}

        {filterFields.peri_care && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.peri_care)}`}></span>
            <b>Peri Care:</b>
            <div className="log-rows__text">{shiftLog.peri_care_text}</div>
          </>
        )}

        {filterFields.nail_care && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.nail_care)}`}></span>
            <b>Nail Care:</b>
            <div className="log-rows__text">{shiftLog.nail_care_text}</div>
          </>
        )}

        {filterFields.bed_linen_change && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_linen_change)}`}
            ></span>
            <b>Bed Linen Change:</b>
            <div className="log-rows__text">{shiftLog.bed_linen_change_text}</div>
          </>
        )}

        {filterFields.clothing_change && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.clothing_change)}`}
            ></span>
            <b>Clothing Change:</b>
            <div className="log-rows__text">{shiftLog.clothing_change_text}</div>
          </>
        )}

        {filterFields.vitals && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.vitals)}`}></span>
            <b>Vitals:</b>
            <div className="log-rows__text">{shiftLog.vitals_text}</div>
          </>
        )}

        {filterFields.medication && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.medication)}`}></span>
            <b>Prescribed Meds Given?:</b>
            <div className="log-rows__text">{shiftLog.medication_text}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="box"
      id="client-shift-log-container"
      data-explorator_test_id="client-shift-log-container"
    >
      <div className="log-display">
        <div>
          {filterFields.clientName && (
            <a className="client-name" href={getClientPath(shiftLog)}>
              {shiftLog.client_name}
            </a>
          )}
        </div>
        <div className="hover-field">
          <a href={getEditShiftLogPath(shiftLog)} className="button is-primary" id="edit-icon">
            <i className="fas fa-edit"></i>
          </a>
          <p className="hover-text">edit</p>
        </div>
      </div>
      <div className={`seen-log ${shiftLog.seen ? "is-seen" : "is-not-seen"}`}>
        <b>{shiftLog.seen ? "Seen" : "Not seen"}</b>
      </div>

      <div className="log-display">
        <div>
          {filterFields.shiftNumber && (
            <>
              <b>Shift Number:</b>
              {` ${shiftLog.shift_num}`}
            </>
          )}
        </div>
        <div>
          {filterFields.location && (
            <>
              <b>Location:</b>
              {` ${shiftLog.location}`}
            </>
          )}
        </div>
      </div>
      <div className="log-display">
        {filterFields.whatHappened && (
          <div>
            <b>Happened at: </b>
            <i className="fas fa-clock"></i>
            {` ${getFormattedDateTime(shiftLog)}`}
          </div>
        )}
        {filterFields.loggedBy && (
          <div>
            <b>Logged by: </b>
            <p className="highlight">{shiftLog.logged_by}</p>
          </div>
        )}
      </div>

      {shiftLog.location === "Diane Morrison Hospice" ? (
        <ShiftLogContainerHospice shiftLog={shiftLog} />
      ) : (
        <OtherLogRows />
      )}

      {filterFields.what_happened && (
        <div className="log-what-happened">
          <b>What happened?</b>
          {shiftLog.log_description ? <ReadMore text={shiftLog.log_description} /> : <p>N/A</p>}
        </div>
      )}
    </div>
  );
};

ShiftLogContainer.propTypes = {
  shiftLog: PropTypes.object.isRequired
};

export default ShiftLogContainer;
